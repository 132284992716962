import React, { useEffect, useState } from "react";
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import "../index.css"
import { BrowserView, MobileView } from 'react-device-detect';
// import components
import Nav from "./Nav";
import NavMobile from "./NavMobile";
import logo from "../logo-2.png";

const Header = () => {
  const [bg, setBg] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 10) {
        setBg(true);
      } else {
        setBg(false);
      }
    });
  });

  return (
    <header
      className={`${
        bg && "bg-white py-1"
      } pb-1 lg:px-0 w-full fixed z-20 transition-all duration-300`}
    >
      <div className="container mx-auto flex items-center justify-between">
        {/* logo */}
        <a href="">
          {/*<img src={Logo} alt='logo image' />*/}
          <BrowserView>
            <div  className="lg:absolute pr-4 pt-4  max-w-[40px] h-[40px] lg:mx-0 ">
              <img src={logo}  alt="" />
            </div>
          <h1 className=" text-2xs uppercase font-size-larger font-bold pt-4 pb-4 pl-8">
            <span className="text-2xs text-wrap text-green-00 font-extrabold">Miracle</span>  <span className="text-2xs text-wrap">Osteo Pain Relief
            Clinic</span>
            <span className={"text-2xs items-center font-extrabold pt-4 pb-4 pl-10 ml-4 "} ><PhoneInTalkIcon  style={{marginRight: 0 + 'em'}} /> (437)-326-7273</span>
          </h1>

            </BrowserView>
        </a>
        <MobileView>
          <div className="container mx-auto flex items-center justify-between">
          <div  className="lg:absolute pr-4 pt-2  max-w-[40px] h-[40px] lg:mx-0 ">
            <img src={logo}  alt="" />
          </div>
        <span className={"text-2xs items-center font-extrabold pt-4 pb-4 "} ><PhoneInTalkIcon  style={{marginRight: 0 + 'em'}} /> (437)-326-7273</span>
          </div>
        </MobileView>
        {/* nav */}
        <Nav />
        {/* nav mobile */}
        <NavMobile />
      </div>
    </header>
  );
};

export default Header;
