import React from "react";

// import image
import {Fade, Slide} from "react-awesome-reveal";
import Image1 from "../assets/img/doctor1.jpeg";
import logo from "../logo-2.png";

// import components
import Header from "./Header";

const Banner = () => {
  return (
    <section
      id="home"
      className="lg:h-full lg:max-h-250 bg-gradient-to-t from-[#C8DF52] to-[#acd8e0]"
    >
      <Header />
      <div className="container mx-auto h-full lg:flex pt-20">
        <div className="text-center mx-auto h-full lg:text-left px-8 md:p-0">
          <Fade cascade damping={0.3}>
          <h1 className="font-primary font-black text-4xl lg:text-6xl text-primary pd-10  mb-4 lg:mt-[30px] leading-tight lg:leading-[70px]">
           Miracle Osteo Pain Relief Clinic
          </h1>
          <span className="text-accent justify-end font-extrabold text-2xl">We care for you.</span>
          <p className="max-w-sm mx-auto mb-[30px] font-bold lg:mx-0 lg:max-w-[540px]">
            Embark on a holistic wellness journey with us ! Dive into tailored sessions for Osteopathy, soothing ayurvedic massage,
            detoxifying & rejuvenating kerala ayurvedic treatments, and invigorating stretch therapy focusing on chronic
            muscular skeletal disorders.
          </p>
          </Fade>
          {/* Appointment */}
          <div  className="lg:absolute  max-w-[450px] h-[395px] lg:mx-0">
            <img src={Image1}  alt="" />
            <h4 className="text-2xl text-center mb-1 pt-2 font-primary font-bold">Dr. Soosanna K Chattu </h4>
            <h5 className="text-1xl text-center mb-1 font-primary font-bold">MD (Ayurveda),
              DO, PhD </h5>
          </div>
        </div>
        <Slide direction={"right"}>
        <div className="hidden max-w-[210px] h-[100px] flex-1 lg:flex lg:flex-col items-end">
          <img src={logo}  alt="" />
        </div>
       </Slide>
      </div>

    </section>
  );
};

export default Banner;
